.form-group {
    position: relative;
    label {
        position: absolute;
        top: -24px;
        color: #7E849A;
        top: 5px;
        font-size: 16px;
    }
}
.form-control {
    background: white;
    border: 1px solid #C1C1C1;
    border-radius: 3px;
    color: black;
    height: 40px;
    font-size: 12px;
    &:focus {
      box-shadow: none;
    }
}
.visibility-icon-wrapper {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    height: 100%;
    align-items: center;
}

label.required {
    &:after {
        content: '*'
    }
}