@mixin xs {
    @media (max-width: 576px){ //<576px
        @content;
    } 
}
@mixin sm {
    @media (max-width: 768px){ //>= 576px
        @content;
    } 
}
@mixin md {
    @media (max-width: 992px){ //>= 768px
        @content;
    } 
}
@mixin lg {
    @media (max-width: 1200px){ //>= 992px
        @content;
    } 
}
@mixin xl {
    @media (min-width: 1200px){ //>= 1200px
        @content;
    } 
}