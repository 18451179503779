/* You can add global styles to this file, and also import other style files */
@import './assets/scss/forms';
@import './assets/scss/mixin';
@import './assets/scss/btn';
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

//showCalendar
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
    background: #3970F4 !important;
}

.e-calendar .e-content .e-footer,
.e-bigger.e-small .e-calendar .e-content .e-footer {
    color: #3970F4 !important;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
    background: #3970F4 !important;
    border: 1px solid #3970F4;
    box-shadow: inset 0 0 0 2px #fff;
    color: #fff;

    &:hover {
        border: 1px solid #3970F4;
        color: #fff;
    }
}

.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
    color: #3970F4 !important;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    border: 1px solid #3970F4 !important;
    &:hover {
        border: 1px solid #3970F4 !important;
    }
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    background: none;
    border: 1px solid #3970F4 !important;
    border-radius: 50%;
    color: #3970F4 !important;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
    background-color: #3970F4 !important;
    border: 1px solid #3970F4 !important;
    box-shadow: inset 0 0 0 2px #fff;
    color: #fff !important;
}

ejs-calendar {
    *{
        font-size: 16px !important;
        @include sm{
            font-size: 13px !important;
        }
    };
    margin: auto;
    max-width: 100% !important;
    width: 100% !important;
    border: none !important;
}

// /showCalendar

* {
    font-family: 'Inter';
}

html, body {
    height: 100%;
    background-color: #F8F8FD;
    font-family: 'Inter';
}

.main-wrapper {
    background-size: cover;
    width: auto;
    background-color: #F8F8FD;
    @include md {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.inner-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    color: black;
    padding-right: 0 !important;
}

.table-hover thead tr:hover th, .table-hover tbody tr:hover td {
    background-color: #ffffff;
}

tbody {
    td {
        p {
            @include sm {
                font-size: 13px;
            }
        }
    }
}

thead {
    th {
        @include sm {
            font-size: 14px !important;
        }
    }
}

tfoot {
    p {
        @include sm {
            font-size: 14px !important;
        }
    }
}


.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    display: block;
    z-index: 15;
    overflow: auto;
}

.snackbar {
    background-color: #EBEEF8;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 10px;
    color: #313337;
    font-weight: 500;

    .mat-simple-snackbar-action {
        color: #3970F4 !important;
    }
}


//tabela
.table-responsive {
    margin-bottom: 50px;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 40px;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.table {
    margin-top: 15px;
    width: 100%;
    height: auto;
    background: white;
    border-collapse: unset;
    border-spacing: 0px;

    thead {
        width: 100%;

        tr {
            height: auto;
        }

        th {
            margin-bottom: 0px;
            font-weight: 500;
            font-size: 15px;
            color: #7E849A;
            border: none;
        }
    }


    tbody {
        border-collapse: unset;
        border-style: none;

        tr {

            &:last-child {
                td:last-child {
                    border-bottom-width: 0;
                }
            }

            td:last-child {
                border-bottom-width: 1px;
            }
        }
    }

    tfoot {
        border-collapse: unset;
        border-style: none;
        border: none;

        td {
            border: none;
            border-style: none;
            border-collapse: unset;
        }
    }

    .showing {
        margin-top: 20px;
        margin-bottom: auto;
        text-align: center;
        justify-content: center;
        width: 100%;
        display: flex;

        p {
            color: #7E849A;
            font-size: 15px;
            font-family: "Inter";
        }

        .left-arrows {
            margin-right: 15px;
            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }
        }

        .right-arrows {
            margin-left: 15px;
            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    tr {
        td {
            vertical-align: middle !important;
            font-weight: 500;
            font-size: 15px;
            color: #152632;

            &:last-child {
                border-bottom-width: 0px;
            }

            .black {
                margin-top: 10px;
                color: #0e0e0e;
            }

            p {
                margin-top: 10px;
            }
        }

        &:last-child {
            td {
                border-bottom-width: 0px;
            }
        }
    }
}

.table-faded {
    color: #7E849A;
}

.arrow {
    float: right;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
}

.flexed-tb {
    display: flex;
}
// /tabela

.scrolling {
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        padding: 2px 0;
        background: #E7E7E7;
        border-radius: 25px;
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #C1C1C1;
        border-radius: 25px;
    }
}

